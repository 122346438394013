<script>
import { useMeta } from 'vue-meta'
import Menu from '@/components/Heineken-cvs-Q3/Menu'

export default {
	name: "Heineken-cvs-Q3_list",
	data() {
		return {
			weektab1:1,
			weektab2:2,
			weektab3:4
		};
	},
	setup () {
		useMeta(
		{ 
			title: '下班就是要海尼根！',
			description: '2024/5/1 - 5/28至指定通路，購買海尼根全系列任三罐啤酒，登錄發票就抽海尼根周邊商品!!!' 
		})		
	},
	components: {
		Menu
	},
	computed: {
	},
	watch: {
	},
	mounted() {
	},
	methods: {
		changeTab(type,id) {
			let vm = this
			if(type == "MONOPOLY"){
				vm.weektab3 = id;
			}else if(type == "chair"){
				vm.weektab2 = id;
			}else if(type == "cart"){
				vm.weektab1 = id;
			}
		},
	},
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
